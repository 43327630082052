import React,{Component}  from 'react';
import {connect} from 'react-redux';
import {reduxForm,Field} from 'redux-form';
import {contactSend} from '../../actions/contactAction';


class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      currentSrc: '',
      open: false,
      fields: {},
      errors: {}
    }
  }
  handleFormSubmit({nom,email,message}){
      const check = nom.split(' ');
      let lastname = '';
      let firstname = '';

      if (check.length > 1) {
          lastname = check[check.length - 1];
          firstname = check[0];
      }

      const obj = {
            'nom': lastname ? lastname : nom,
            'email':email,
            'tel':'',
            'message':message,
            'prenom':firstname
          }
      this.props.contactSend(obj);
      this.setState({addClass: !this.state.addClass})
    };

  renderInput = ({ input, label, type,placeholder, meta: { touched, error,pristine,invalid,submitted }}) => (
      <div className="form-group">
      <label htmlFor={label}>{label}</label>
        <input
          className={ touched && invalid ? "form-control is-invalid" : 'form-control'}
          {...input}
          type={type}
          placeholder={placeholder}
        />
        { touched && error && <span className="error">{error}</span> }
      </div>
    );

  rendertextarea = ({input,meta: { touched, error,invalid,submitted }}) => (
    <div>
      <textarea {...input}
      className={ touched && invalid ? "form-control is-invalid" : 'EmailContent form-control'} 
      cols="30" 
      id="EmailContent" 
      rows="5" />
      { touched && error && <span className="error">{error}</span> }
    </div>
  );
  toggle(){
      
  }

  render() {
    const {handleSubmit} = this.props
      let showSuccess = ["hideSuccess"];            
      if(this.state.addClass) {
        showSuccess.push('showSuccess');
      }          
    return(
            <div className="bloc contact">
                <div className="blocArrondi elt">
                  <p className="color">Nous écrire par mail</p>
                  <p>Pour nous contacter, renseigner les champs suivants et cliquer sur envoyer.</p>
                  <hr/>
                  <span className={showSuccess.join(' ')}>Votre e-mail est envoyé.</span>
                  <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
                      <div className="form-group">
                         <label htmlFor="destinataire">Destinataire</label>
                         <select 
                            name="" 
                            id="destEmail"
                            className="form-control"
                         >
                              <option value="marketing@servicepostal.com">marketing@servicepostal.com</option> 
                         </select>
                      </div>
                      <Field name="nom" label="Nom" component={this.renderInput} type="text" placeholder="Nom,Prenom*" required={true} />
                      <Field name="email" label="Email" component={this.renderInput} type="text" placeholder="Email*" required={true} />
                      <Field name="message" component={this.rendertextarea} required={true} />
                      <p>
                        <span className="small">* champs obligatoires</span> 
                        <input type="submit" className="bt btBlue pull-right" value="Envoyer" onClick = {this.toggle.bind(this)}/>
                      </p>
                    </form>   
                    <div className="blocCall">
                        <p className="blue">Pour nous <strong>contacter par téléphone</strong>, appellez nous au</p>
                        <div className = "callUs">
                          <div className="blocNumber">
                            <a className="numberCall" href="tel:+3309500950" target="_blank" rel="noopener noreferrer">
                              09 50 09 50 00
                            </a>
                              <span className="serviceCall">Service & appel gratuits</span>
                          </div>
                        <p>du lundi au vendredi (hors jours fériés) de 9h-12h15 et 13h45-18h.</p>
                  </div>
                </div>
               </div> 
            </div>    
      )
  }
}

function validate(values) {
  const errors = {};
  if (!values.nom) {
      errors.nom = 'Merci de saisir votre nom.';
    }
    if (typeof values.nom !== "undefined") {
        if (!values.nom.match(/^[a-zA-Z ]*$/)) {
          errors.nom = "Merci de saisir des caractères alphabet.";
        }
      }
    if (!values.email) {
      errors.email = 'Merci de saisir votre Email.';
    }

    if (typeof values.email !== "undefined") {
        const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(values.email)) {
           errors.email = "Merci de saisir une adresse email valide.";
        }
    }

    if (!values.message) {
       errors.message = 'Merci de saisir votre message.';
    }
    return errors;
}

ContactForm = reduxForm({
  form: 'contact_form',
  validate: validate
})(ContactForm)


function mapStateToProps(state, ownProps) {
  let a_connected = state.login.connected;
  let _st = '';
  let init = '';

  if (a_connected){
      _st = a_connected.code === 200 ? a_connected.data : window.location.reload(true)
      
      if (_st) {
        init = [_st.email,_st.name] 
      }         
  }

  return {
    initialValues: {
      email:init[0],
      nom:init[1]
    },
    enableReinitialize: true,
  }
}


export default connect(mapStateToProps,{contactSend})(ContactForm);
