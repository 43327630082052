import React  from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';

import '../../assets/css/aside.css';
import "react-datepicker/dist/react-datepicker.css";
import 'rc-collapse/assets/index.css';
import Collapse, { Panel } from 'rc-collapse';
import Preference from './preference';
import FacturesMensuelle from './facturesMensuelle';
import Serviceplugin from './serviceplugin';
import {subscritiptionFacture,clearSubscrFacture} from '../../actions/factureAction';

class Aside extends React.Component {
  constructor() {
      super();
       this.state = {
          addClass: false,
          accordion: true,
          activeKey: ['1'],
         }
  }
  componentDidMount() {
    this.props.subscritiptionFacture();       
  }

  componentWillUnmount() {
    this.props.clearSubscrFacture();          
  }

  onChange = (activeKey) => {
    this.setState({
      activeKey,
    });
  }

  toggle() {
      this.setState({addClass: !this.state.addClass});
  }

  getItems() {
    const items = [];
    items.push(
      <Panel header={`mes factures mensuelles`} key="1">
          <FacturesMensuelle/>
      </Panel>
    );

     items.push(
      <Panel header={`mes préférences d'envoi`} key="2"> 
           <Preference />
      </Panel>
    );
    return items;
  }

  getHtmlSubscription(){
    let subscriptionType = this.props.subscriptionType;
    let subscriptionState = this.props.subscriptionState;
    switch(subscriptionState){
      case "sub_no_valid":
        return (<div className="notSubs text-center">
                  <p className="title"><strong>Vous n'êtes pas encore abonné.</strong></p>
                  <p>Pour pouvoir effectuer des envois api en production, vous devez activer votre abonnement.</p>
                  <Link to = "/abonnement" className="bt" title="S'abonner">s'abonner</Link>
                </div>)
      case "sub_waiting":
        return (<div className="enCours text-center">
                    <p><strong>Abonnement en cours d'activation</strong> Votre abonnement est en cours de vérification par l'équipe ServicePostal et sera validé dans les plus brefs délais. En cas de besoin, nous sommes joignable au <a href="/" className="telNumber">09 50 09 50 00 du lundi au vendredi entre 9h-12h15 et 13h45-18h</a></p>
              </div>)
      case "sub_valid":
        return (<div className="abonner text-center">
                  <p><strong>Abonnement <span>{subscriptionType}</span></strong>Vous pouvez désormais profiter des envois api en production</p>
                </div>)
      default:
        return (<p>Abonnement en chargement...</p>)
      
    }
    
  }
 
  render() {
    const accordion = this.state.accordion;
    return( 
      <div className="wrapAside">
        {/*eslint-disable */}
        {/*<a className="bt-Big" href={SP_URL} title="Préparer un envoi unitaire" target="_blank" rel="noopener noreferrer">préparer un envoi unitaire</a>
        <a className="bt-Big" href={PUB_URL} title="préparer un envoi en nombre" target="_blank" rel="noopener noreferrer">préparer un envoi en nombre</a>*/}
        <div className="mescredits displayNone">
          <p><strong>mes crédits</strong></p>
          <p className="yellow">reste actuellement <span>(26/05/2014 14.10)</span></p>
          <p><span className="left">2</span> <span className="text">Lettres Simples</span></p>
          <p className="mb-5"><span className="zero left">0</span><span className="text">Lettre recommandée avec accusé de réception</span></p>
          <a href="/" className="yellow" title="visualiser le détail">visualiser le détail</a>
          <a className="recharge" href="/" title="Recharger des Crédits">Recharger des Crédits</a>
        </div>
        <div className="mesfactures displayNone">
          <Collapse
                  accordion={accordion}
                  onChange={this.onChange}
              >
                {this.getItems()}
          </Collapse>
        </div>    
        <div className = "displayNone">
          <Serviceplugin />
        </div>
        <div className="subscription">       
            {this.getHtmlSubscription()}
        </div>
      </div>
     )
    }
}

function mapStateToProps(state, ownProps) {
  let a_status = state.user.profiled;
  let auto_c = state.user.uriSp;
  let auto_pub = state.user.uri;
  let idSp = '';
  let idPub = '';
  let setAuto = '';
  let setPub = '';

  let subscriptionType = '';
  let subscriptionState = '';
  let sub = state.subinfo.sub_info;
  if (sub){
    subscriptionType = sub.subscription_type;
    subscriptionState = sub.subscription_state;
  }

  if (a_status) {
    idSp = a_status.data.idSp;
    idPub = a_status.data.idPub;
  }

  if (auto_c) {
      setAuto = auto_c.data ? auto_c.data.uri : '';
  }

  if (auto_pub) {
      setPub = auto_pub;
  }

  return {
    id_sp:idSp,
    id_pub:idPub,
    connectAs:setAuto,
    connectPub:setPub,
    subscriptionType: subscriptionType,
    subscriptionState: subscriptionState,
  }
}
export default connect(mapStateToProps,{
  subscritiptionFacture,
  clearSubscrFacture})(Aside);