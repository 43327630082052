import React  from 'react';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import Collapsible from 'react-collapsible';

import Loader from './loader';
import {updateArchived} from '../../actions/envoiesActions';
import {RenderAffrachissement,RenderColor,RenderPli,RenderVerso,RenderSuivie,RenderEtat,RenderAddress} from './unitaire/afficheUnitaire';
import {fetchLettersSend,fetchPreuvedepot,fetchLettersSend2} from '../../actions/factureAction';
import ARScanImg from '../../assets/images/ARScanSmall.jpg'

class List extends React.Component {

	constructor() {
      super();
      this.state = {
      	isLoader: false,
      	isArchived: true,
      	open: false
      }
      this.handleArchived = this.handleArchived.bind(this);
      // this.openModal = this.openModal.bind(this)
      this.closeModal = this.closeModal.bind(this);	  
    }
	
    componentDidUpdate(prevProps) {
		if (this.props.initial !== prevProps.initial) {
			this.setState({isLoader: false})
		}

		if (this.props.error !== prevProps.error && this.props.error) {
			this.setState({open:true})
			this.setState({isLoader: false})
		}

		if (this.props.message !== prevProps.message) {
			this.setState({isLoader: false})
		}
	}

	openFiles (element){
    	const obj = {'orderId':element.id}
		this.setState({isLoader: true})
    	this.props.fetchLettersSend2(obj);
    }
    closeModal () {
        this.setState({ open: false })
    }

    openPreuveDepot(element){
    	const obj = {'orderId':element.id}
		this.setState({isLoader: true})
    	this.props.fetchPreuvedepot(obj);
    }
	
	renderTab(element){
		let aff = '';
		let recto = '';
		let type = '';
		let color = '';
		let pli = '';
		let env = '';

		if (element.type_courier === 1) {
			aff = 'LRAR';
		} else if(element.type_courier === 2) {
			aff = 'LR';
		} else if(element.type_courier === 3) {
			aff = 'Prioritaire';
		} else if(element.type_courier === 4) {
			aff = 'Verte';
		} else if(element.type_courier === 5) {
			aff = 'Ecopli';
		} else if(element.type_courier === 6) {
			aff = 'Suivi';
		} else if(element.type_courier === 7) {
			aff = 'Performance';
		} else if(element.type_courier === 8) {
			aff = 'Perf. Suivie';
		} else if(element.type_courier === 9) {
			aff = 'Verte Suivie';
		}

		if (element.envelop < 3) {
			type = 'Fenêtre';
		} else {
			type = 'imprimée';
		}

		if (element.rect_verso === 1) {
			recto = 'Recto-Verso';
		} else {
			recto = 'Recto';
		}

		if (element.options_impression === 1) {
			color = 'Couleur';
		} else {
			color = 'N&B';
		}

		if (element.envelop === 0 || element.envelop === 5) {
			pli = 'plié en trois';
			env = 'C6'
		} else if (element.envelop === 1 || element.envelop === 6) {
			pli = 'plié en deux';
			env = 'C5'
		}  else if (element.envelop === 2 || element.envelop === 7) { 
			pli = 'non plié';
			env = 'C4';
		}

		return(
			<div className="line" key={element.id}>
				<div className={element.deleted === 1 ? 'case tbody archive':'case tbody'} key={element.id}>
					<div className="hideMob">
						<div className="casex case1" key={element}>{element.date}<br/><i>{element.time}</i></div>
						<div className="casex case3">{aff}<br/><RenderAffrachissement element={element.type_courier} /></div>
						<div className="casex case4">{element.destinataire}</div>
						<div className="casex case5">{element.refer_client}</div>
						<div className="casex case6">
							<Popup
								trigger={
									<span>
										<RenderEtat element={element} />
									</span>
								}
								position="top center"
								on ="hover"
								closeOnDocumentClick
								>
								{element.etat.title}
							</Popup>
						</div>
						<div className="casex case7">
							<Popup
								trigger={
										<span>
											<RenderPli element={element} />				                  
											<RenderColor element={element} />
											<RenderVerso element={element}/>
										</span>
									}
								on ="hover"
								position="top center"
								closeOnDocumentClick
							>
								{`Enveloppe ${type} ${env} - A4 ${pli} - ${color} - ${recto}`}             
							</Popup>
						</div>
						<div className="casex case8 popText">
							<Popup
								open={this.state.open}
								closeOnDocumentClick
								onClose={this.closeModal}
								>
								<div className="wrap connexion formulaire">
									<div className="bloc noborder modal">						          
										<button className="close" onClick={this.closeModal}>
										&times;
										</button>
										{this.props.message}
										<button onClick={this.closeModal} className="bt">OK</button>
									</div>
								</div>
							</Popup>
							<button onClick={() => this.openFiles(element)}>
								<i className="material-icons-outlined">description</i>
							</button>
						</div>
						<div className="casex case10">{element.nb_pages}</div>
						<div className="casex case12">
							<Popup
								trigger={element.deleted !== 1 ? (<button><i className="material-icons-outlined" onClick={() => this.handleArchived(element)}>archive</i></button>):(<button><i className="material-icons-outlined" onClick={() => this.handleArchived(element)} >unarchive</i></button>)}
								position="top center"
								on ="hover"
								closeOnDocumentClick
								>
								{element.deleted !== 1 ? 'Archiver' : 'Restaurer'}
							</Popup>
						</div>
						<div className="hide-line">
							<div className="mb-block name item-flex">
								<div className="first-item">
									<RenderSuivie element={element}/>
								</div>
								<div className="second-item">
									<p><strong>Adresse destinataire</strong><br/>
										<span>{element.destinataire}</span><br/>
										<RenderAddress element={element}/><br/>
										<span>{element.zipcode} {element.city}</span><br/>
										<span>{element.country}</span>
									</p>
								</div>
								{/*<div className="third-item">
									<p>
										<strong>Facture</strong><br/>
										<span>
											<a href="#" alt=""><img alt="" border="0" width="18" height="24" src={FactureImg} /></a>
										</span>
									</p>
								</div>*/}
							</div>
						</div>
					</div>
					<div className="mob-line">
						<div className="mobVersion">
							<Collapsible trigger={<div className="header-collapse"><div className="casex case1">{element.date}<br/> <i>{element.time}</i></div><div className="casex case3">{aff}<br className="hideMob"/><RenderAffrachissement element={element.type_courier} /></div><div className="casex case2"><span className="arrows"></span></div></div>}>
								<div className="full">
									<div className="part">							
										<div className="mb-block name">
											<p><strong>Nom du destinataire</strong><span>{element.destinataire}</span></p>
										</div>
										<div className="mb-block name">
											<p>
												<strong>Adresse destinataire</strong>
												<span>7 rue des Fleurs</span>
												<span>Villa Eden</span>
												<span>Bat B</span>
												<span>37000 Tours</span>
												<span>France</span>
											</p>										
										</div>
										<div className="mb-block options">
											<Popup
												trigger={
														<p>
															<strong className="bold">Options d'impression</strong>
															<RenderPli element={element} />				                  
															<RenderColor element={element} />
															<RenderVerso element={element}/>
															<span>{element.nb_pages} page</span>
														</p>
													}
												on ="hover"
												position="top center"
												closeOnDocumentClick
											>
												{`Enveloppe ${type} ${env} - A4 ${pli} - ${color} - ${recto}`}             
											</Popup>
										</div>
										<div className="mb-block">
											<span><strong>Référence client </strong>{element.refer_client}</span>
										</div>
										<div className="mb-block suivre"><RenderSuivie element={element}/></div>
										<div className="mb-block documents">
											<Popup
												open={this.state.open}
												closeOnDocumentClick
												onClose={this.closeModal}
												>
													<div className="wrap connexion formulaire">
														<div className="bloc noborder modal">						          
															<button className="close" onClick={this.closeModal}>
															&times;
															</button>
															{this.props.message}
															<button onClick={this.closeModal} className="bt">OK</button>
														</div>
													</div>
												</Popup>
										<button onClick={() => this.openFiles(element)}>
											Télécharger le document
										</button>
										{
											element.type_courier === 6 || element.type_courier === 1 || element.type_courier === 2 ? 
											(<button onClick={() => this.openPreuveDepot(element)}>
												<i className="material-icons-outlined">description</i>
											</button>) : null
										}
										</div>
										<div className="mb-block documents">
											<Popup
												trigger={
													<span>
														<i className="material-icons-outlined ArScan-help" style={{display: "block"}}>help</i>
													</span>
												}
												position="top left"
												on ="hover"
												closeOnDocumentClick
												className="arScan"								
											>
												<strong>Vous avez demandé pour ce courrier un AR dématérialisé. 
												Celui-ci n'a pas encore été reçu par nos services (cela peut prendre quelques jours voire quelques semaines dans certains cas); dès qu'il sera réceptionné, notre équipe le scannera et il sera disponible ici.</strong>
											</Popup>
											<button className="ArScanLink"><img alt="" border="0" width="45" src={ARScanImg} style={{ display: "none" }}/></button>
										</div>
									</div>
									<div className="archive-block">
										<div className="casex case12">
											<Popup
												trigger={element.deleted !== 1 ? (<button><i className="material-icons-outlined" onClick={() => this.handleArchived(element)}>archive</i></button>):(<button><i className="material-icons-outlined" onClick={() => this.handleArchived(element)} >unarchive</i></button>)}
												position="top center"
												on ="hover"
												closeOnDocumentClick
												>
												{element.deleted !== 1 ? 'Archiver' : 'Restaurer'}
											</Popup>
										</div>
									</div>
								</div>
							</Collapsible>
						</div>						
					</div>
				</div>
			</div>		
		)
	}

	handleArchived(element){
		const obj = {
			'id':element.id,
			'status': element.deleted ? false : true,
			'isArchived':this.props.archive
		}

		this.props.updateArchived(obj)
		this.setState({isLoader:true})
	}

	render() {
		return(
			<div className="tableau">
				<Loader isLoader={this.state.isLoader} />
				<div className="hideMob case thead">
					<p className="case1">Date</p>
					<p className="case3">Type de courrier</p>
					<p className="case4">Destinataire</p>
					<p className="case5">Réf.</p>
					<p className="case6">Etat</p>
					<p className="case7">Options d'impression</p>
					<p className="case8">Documents</p>
					<p className="case10">Nb de pages</p>
					<p className="case12">Archive</p>
				</div>
				{this.props.data && this.props.data.length >0 ? (
					this.props.data.map(el => {			        		
						return this.renderTab(el)
					})
				): (<div></div>)
				}			        		     				        
		   </div>
		);
	}
}


function mapStateToProps(state, ownProps) {
	let a_status = state.envoies.list_unitaire;
	let files = state.envoies.fetch_letters;
	let _st;
	let error = '';
	let message = '';

	if (a_status) {
		if (a_status.code === 200) {
		    _st = a_status.data
		    message = a_status.message
		} else {
			_st = ''
		}
	}

	if (files) {
		if (files.code !== 200) {
			error = new Date().getTime() + ' ' + files.code;
			message = state.envoies.messagedata
		} else {
			error = '';
			message = files.data.name
		}
	}

	return {
		initial: _st ? _st : '',
		error:error,
		message: message
	}
}
export default connect(mapStateToProps, {updateArchived,fetchLettersSend,fetchLettersSend2,fetchPreuvedepot})(List);
